<template>
  <v-card class="pa-4" flat>
    <div>
      <v-tabs v-model="tab" center-active>
        <v-tab>
          {{ $t("gos.group") }}
        </v-tab>
        <v-tab>
          {{ $t("gos.general") }}
        </v-tab>
      </v-tabs>
    </div>

    <v-row no-gutters align="center" class="mt-3">
      <FilterSchoolYear :withInitial="true" notMultiple @handler="getFilter" />
      <v-spacer></v-spacer>
      <v-btn
        v-if="tab == 0 && filter.class"
        color="primary"
        class="caption"
        depressed
        @click="openGosForm = true"
      >
        {{ $t("gos.add_gos") }}
        <v-icon class="ml-2">mdi-plus</v-icon>
      </v-btn>
    </v-row>

    <GosForm
      :openGosForm="openGosForm"
      :closeHandler="data => closeForm(data)"
      :dataEdit="dataEdit"
      :quaryFilter="filter"
      :filterList="filterList"
      :groupList="groupList"
      @triggerData="() => triggerGetData()"
    />

    <v-tabs-items v-model="tab" touchless>
      <v-tab-item>
        <GroupList
          :tab="tab"
          :triggerData="getData"
          :filter="filter"
          @setDataEdit="data => setEdit(data)"
        />
        <!--  -->
      </v-tab-item>
      <v-tab-item>
        <GeneralList :tab="tab" :triggerData="getData" :filter="filter" />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import FilterSchoolYear from "../../components/filterSchooYear";
import GroupList from "./GroupList";
import GeneralList from "./GeneralList";
import GosForm from "./GosForm";

export default {
  components: {
    FilterSchoolYear,
    GroupList,
    GeneralList,
    GosForm
  },
  data() {
    return {
      tab: 1,
      filter: {
        school_year: "",
        grade: "",
        class: ""
      },
      filterList: {},
      dataEdit: {},
      groupList: [],
      getData: false,
      openGosForm: false
    };
  },
  mounted() {
    this.tab = 0;
  },
  methods: {
    triggerGetData() {
      this.getData = false;
      setTimeout(() => {
        this.getData = true;
      }, 200);
    },
    setEdit(item) {
      this.dataEdit = item.editData;
      this.groupList = item.groupList;
      this.openGosForm = true;
    },
    getFilter(res) {
      this.getData = false;
      this.filterList = res.master;

      switch (res.type) {
        case "school_year":
          this.filter.school_year = res.data.id;
          break;
        case "select_grade":
          this.filter.grade = res.data;
          break;
        case "select_class":
          this.filter.class = res.data;
          this.triggerGetData();
          break;
        default:
          break;
      }
    },
    closeForm(param) {
      this.getData = false;
      this.dataEdit = {};
      if (param) this.triggerGetData();
      this.openGosForm = false;
    }
  }
};
</script>
