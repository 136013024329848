<template>
  <div class="mt-3">
    <v-data-table
      :headers="tableHeaders"
      :items="dataTable"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [10, 25, 50, 100]
      }"
      show-expand
      class="elevation-0"
    >
      <template v-slot:expanded-item="{ item, headers }">
        <td :colspan="headers.length">
          <v-simple-table class="my-4">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">{{ $t("gos.table.subject") }}</th>
                  <th class="text-left">{{ $t("gos.table.show") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="r in item.details" :key="r.id">
                  <td>{{ r.lesson }}</td>
                  <td style="width: 200px">
                    <v-checkbox
                      v-model="r.is_exclude"
                      @change="setStatus(r)"
                    ></v-checkbox>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </td>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-btn
          icon
          small
          color="primary"
          :disabled="loading"
          @click="editRating(item)"
        >
          <v-icon small>mdi-pencil-box-outline</v-icon>
        </v-btn>
        <v-btn icon small color="error" @click="setDeleteGos(item)">
          <v-icon small>mdi-delete-forever</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <ModalConfirm
      :title="$t('gos.delete_confirm')"
      :close="() => (this.confirmDelete = false)"
      :loading="loadingBtn"
      :isOpen="confirmDelete"
      :save="submitDeleteGos"
    />
  </div>
</template>

<script>
import i18n from "@/i18n";
import {
  getListGoS,
  deleteGos,
  updateLessonShowOnRaport
} from "@/api/admin/settings/groupOfSubject";

import ModalConfirm from "@/components/ModalConfirm";

export default {
  props: {
    tab: [String, Number],
    triggerData: Boolean,
    filter: Object
  },
  components: {
    ModalConfirm
  },
  data() {
    return {
      tableHeaders: [
        {
          text: i18n.t("gos.table.name"),
          value: "label"
        },
        {
          text: i18n.t("app.action"),
          sortable: false,
          value: "action",
          width: 150
        }
      ],
      dataTable: [],
      loading: false,
      confirmDelete: false,
      loadingBtn: false,
      selectedRating: null
    };
  },
  watch: {
    tab() {
      this.getData();
    },
    triggerData(nv) {
      if (nv) this.getData();
    }
  },
  methods: {
    setStatus(item) {
      this.loading = true;
      updateLessonShowOnRaport({
        lesson_master: item.id,
        class_master: this.filter.grade,
        classroom: this.filter.class
      })
        .then(res => {
          if (res.data.code) {
            this.$store.commit("CALL_SNACKBAR", {
              msg: res.data.message,
              color: "success"
            });
          } else {
            this.$store.commit("CALL_SNACKBAR", {
              msg: res.data.message,
              color: "error"
            });
          }
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    getData() {
      if (this.tab == 0 && this.filter.school_year) {
        this.dataTable = [];
        const query = {
          school_year: this.filter.school_year,
          class_master: this.filter.grade,
          classroom: this.filter.class
        };
        this.loading = true;
        getListGoS(query)
          .then(res => {
            if (res.data.code) {
              this.dataTable = res.data.data;
            } else {
              this.$store.commit("CALL_SNACKBAR", {
                msg: res.data.message,
                color: "error"
              });
            }
            this.loading = false;
          })
          .catch(() => (this.loading = false));
      }
    },
    editRating(item) {
      this.$emit("setDataEdit", { editData: item, groupList: this.dataTable });
    },
    setDeleteGos(item) {
      this.selectedRating = item;
      this.confirmDelete = true;
    },
    submitDeleteGos() {
      if (this.selectedRating) {
        this.loadingBtn = true;
        deleteGos({ id: this.selectedRating.id })
          .then(res => {
            if (res.data.code) {
              this.$store.commit("CALL_SNACKBAR", {
                msg: i18n.t("app.success_delete"),
                color: "success"
              });
              this.selectedRating = null;
              this.confirmDelete = false;
              this.getData();
            } else {
              this.$store.commit("CALL_SNACKBAR", {
                msg: res.data.message,
                color: "error"
              });
            }
            this.loadingBtn = false;
          })
          .catch(() => {
            this.loadingBtn = false;
          });
      }
    }
  }
};
</script>
