import request from "@/utils/request";

export function createGoS(data) {
  return request({
    method: "post",
    url: "report_lesson_group/create",
    data: data
  });
}

export function getListGoS(data) {
  return request({
    method: "post",
    url: "report_lesson_group/list",
    data: data
  });
}

export function getListGoSGeneral(data) {
  return request({
    method: "post",
    url: "report_lesson_group/list_general_lessons",
    data: data
  });
}

export function deleteGos(data) {
  return request({
    method: "post",
    url: "report_lesson_group/delete",
    data: data
  });
}

export function updateLessonShowOnRaport(data) {
  return request({
    method: "post",
    url: "report_lesson_group/update_excluded",
    data: data
  });
}

export function moveGroupOfSubject(data) {
  return request({
    method: "post",
    url: "report_lesson_group/move_lesson",
    data: data
  });
}

export function updateGroupOfSubject(data) {
  return request({
    method: "post",
    url: "report_lesson_group/delete_insert",
    data: data
  });
}
