<template>
  <div class="mt-3">
    <v-alert
      v-if="dataTable.length > 0"
      class="mb-2"
      dense
      colored-border
      border="left"
      text
      color="primary"
      >{{ $t("gos.note") }}</v-alert
    >
    <v-data-table
      :headers="tableHeaders"
      :items="dataTable"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [10, 25, 50, 100]
      }"
      class="elevation-0"
    >
      <template v-slot:[`item.is_exclude`]="{ item }">
        <v-checkbox
          v-model="item.is_exclude"
          @change="setStatus(item)"
        ></v-checkbox>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import i18n from "@/i18n";
import {
  getListGoSGeneral,
  updateLessonShowOnRaport
} from "@/api/admin/settings/groupOfSubject";

export default {
  props: {
    tab: [String, Number],
    triggerData: Boolean,
    filter: Object
  },
  data() {
    return {
      tableHeaders: [
        {
          text: i18n.t("gos.table.subject"),
          value: "lesson"
        },
        {
          text: i18n.t("app.action"),
          sortable: false,
          value: "is_exclude",
          width: 150
        }
      ],
      dataTable: [],
      loading: false
    };
  },
  watch: {
    tab() {
      this.getData();
    },
    triggerData() {
      this.getData();
    }
  },
  methods: {
    setStatus(item) {
      this.loading = true;
      updateLessonShowOnRaport({
        lesson_master: item.id,
        class_master: this.filter.grade,
        classroom: this.filter.class
      })
        .then(res => {
          if (res.data.code) {
            this.$store.commit("CALL_SNACKBAR", {
              msg: i18n.t("app.success_edit"),
              color: "success"
            });
          } else {
            this.$store.commit("CALL_SNACKBAR", {
              msg: res.data.message,
              color: "error"
            });
          }
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    getData() {
      const q = this.filter;
      const condition = q.school_year && q.grade && q.class;
      if (this.tab == 1 && condition) {
        this.dataTable = [];
        const query = {
          school_year: q.school_year,
          class_master: q.grade,
          classroom: q.class
        };
        this.loading = true;
        getListGoSGeneral(query)
          .then(res => {
            if (res.data.code) {
              this.dataTable = res.data.data;
            } else {
              this.$store.commit("CALL_SNACKBAR", {
                msg: res.data.message,
                color: "error"
              });
            }
            this.loading = false;
          })
          .catch(() => (this.loading = false));
      }
    },
    paginateChange(page, limit) {
      this.queryData.page = page;
      this.queryData.limit = limit;
      this.getData();
    }
  }
};
</script>
