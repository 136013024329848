<template>
  <v-row justify="center">
    <v-dialog
      v-model="openGosForm"
      transition="scale-transition"
      max-width="700px"
      persistent
      scrollable
    >
      <v-card outlined>
        <v-card-title class="subtitle-1 font-weight-bold">
          {{ dataEdit.id ? $t("gos.update_form") : $t("gos.create_form") }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-0">
          <v-form ref="GosForm" lazy-validation>
            <v-row class="mt-6">
              <v-col cols="4">
                <v-select
                  v-model="quaryFilter.school_year"
                  :rules="[v => !!v || $t('app.required')]"
                  :label="$t('app.school_year')"
                  :items="filterList.schoolYearList"
                  item-value="id"
                  :item-text="
                    item =>
                      `${item.start_year} / ${item.end_year}, Semester ${item.semester}`
                  "
                  dense
                  outlined
                  disabled
                >
                </v-select>
              </v-col>
              <v-col cols="4">
                <v-select
                  v-model="quaryFilter.grade"
                  :rules="[v => !!v || $t('app.required')]"
                  :label="$t('app.grade')"
                  :items="filterList.gradeList"
                  item-value="id"
                  item-text="grade"
                  dense
                  outlined
                  disabled
                >
                </v-select>
              </v-col>
              <v-col cols="4">
                <v-select
                  v-model="quaryFilter.class"
                  :rules="[v => !!v || $t('app.required')]"
                  :label="$t('app.class')"
                  :items="filterList.classList"
                  item-value="id"
                  item-text="name"
                  dense
                  outlined
                  disabled
                >
                </v-select>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="form.label"
                  :rules="[v => !!v || $t('app.required')]"
                  :label="$t('gos.form.name_group_subject')"
                  name="nameGroupSubject"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-row class="mb-4">
                  <v-col v-if="!isEdit" cols="12" sm="6">
                    <v-checkbox
                      v-model="form.add_to_grade"
                      :label="`${$t('gos.select_for_all_grade')} ${getGrade()}`"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" :sm="!isEdit ? 6 : 12">
                    <div class="d-flex justify-end">
                      <v-text-field
                        v-model="search"
                        outlined
                        hide-details
                        dense
                        autocomplete="off"
                        style="max-width: 220px"
                        :label="$t('app.search')"
                      ></v-text-field>
                    </div>
                  </v-col>
                </v-row>
                <v-data-table
                  v-model="form.subjects"
                  :headers="subjectHeader"
                  :items="subjectData"
                  :loading="loading"
                  :search="search"
                  class="elevation-0"
                  item-key="id"
                  show-select
                >
                  <template v-slot:[`item.action`]="{ item }">
                    <v-btn
                      v-if="item.isEdit"
                      icon
                      color="primary"
                      :disabled="loading"
                      @click="moveOtherCategory(item)"
                    >
                      <v-icon small>mdi-cursor-move</v-icon>
                    </v-btn>
                  </template>
                  <template
                    v-slot:[`item.data-table-select`]="{ isSelected, select }"
                  >
                    <v-simple-checkbox
                      :value="isSelected"
                      @input="select($event)"
                    >
                    </v-simple-checkbox>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider class="my-2"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            color="primary"
            @click="
              () => {
                closeHandler(null);
                subjectData = [];
              }
            "
            >{{ $t("app.cancel") }}</v-btn
          >
          <v-btn
            class="gradient-primary"
            :loading="loadingSave"
            depressed
            dark
            @click="save"
            >{{ $t("app.save") }}</v-btn
          >
        </v-card-actions>
      </v-card>

      <v-dialog
        v-model="openMoveDialog"
        transition="scale-transition"
        max-width="500px"
        persistent
      >
        <v-card outlined>
          <v-card-title class="subtitle-1 font-weight-bold">
            {{ $t("gos.move_group") }} :
            {{ formMove.lesson.lesson }}
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pt-6">
            <v-form ref="GosMoveForm" lazy-validation>
              <v-text-field
                :value="form.label"
                readonly
                outlined
                dense
              ></v-text-field>
              <v-autocomplete
                v-model="formMove.moveTo"
                :label="$t('gos.select_gos')"
                :items="groupList"
                item-text="label"
                item-value="id"
                :item-disabled="r => r.id == dataEdit.id"
                outlined
                dense
              >
              </v-autocomplete>
            </v-form>
            <v-row no-gutters class="mt-2">
              <v-spacer></v-spacer>
              <v-btn
                class="mr-2"
                color="primary"
                outlined
                @click="openMoveDialog = false"
                >{{ $t("app.cancel") }}</v-btn
              >
              <v-btn color="primary" depressed @click="submitMoveGroup">{{
                $t("app.save")
              }}</v-btn>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-dialog>
  </v-row>
</template>

<script>
import i18n from "@/i18n";
import { isNumberOnly } from "@/utils/validate";

import moment from "moment/moment";
moment.locale("id");

import {
  getListGoSGeneral,
  updateGroupOfSubject,
  moveGroupOfSubject,
  createGoS
} from "@/api/admin/settings/groupOfSubject";

export default {
  props: {
    openGosForm: Boolean,
    closeHandler: Function,
    dataEdit: Object,
    quaryFilter: Object,
    filterList: Object,
    groupList: Array
  },
  data() {
    return {
      search: "",
      loadingSave: false,
      scoreTypeList: [
        { value: "K1K2", label: "K1 / K2" },
        { value: "K3K4", label: "K3 / K4" }
      ],
      subjectHeader: [
        {
          text: i18n.t("app.subject"),
          value: "lesson"
        },
        {
          text: i18n.t("app.action"),
          value: "action"
        },
        {
          text: "",
          value: "data-table-select"
        }
      ],
      loading: false,
      subjectData: [],
      loadingMove: false,
      formMove: {
        lesson: {},
        moveTo: {}
      },
      openMoveDialog: false,
      isEdit: false,
      form: {
        add_to_grade: false,
        label: "",
        subjects: []
      }
    };
  },
  watch: {
    async openGosForm(val) {
      if (val) {
        this.isEdit = false;
        this.form = {
          label: "",
          subjects: []
        };
        if (this.$refs.GosForm) {
          this.$refs.GosForm.resetValidation();
        }

        if (this.dataEdit.id) {
          this.isEdit = true;
          const d = this.dataEdit;
          this.form = {
            label: d.label,
            subjects: d.details
          };
          d.details.map(r => {
            this.subjectData.push({ ...r, isEdit: true });
          });
        }

        const f = this.quaryFilter;
        const query = {
          school_year: f.school_year,
          class_master: f.grade,
          classroom: f.class
        };

        this.loading = true;
        const getSubject = await getListGoSGeneral(query);
        getSubject.data.data.map(r => {
          this.subjectData.push({ ...r, isEdit: false });
        });
        this.loading = false;
      }
    }
  },
  methods: {
    getGrade() {
      if (this.filterList.gradeList && this.quaryFilter.grade) {
        return this.filterList.gradeList.find(
          e => e.id == this.quaryFilter.grade
        ).grade;
      } else return "";
    },
    isNumberOnly: evt => isNumberOnly(evt),
    moveOtherCategory(item) {
      this.formMove.lesson = item;
      this.formMove.moveTo = "";
      this.openMoveDialog = true;
    },
    submitMoveGroup() {
      if (this.$refs.GosMoveForm.validate()) {
        this.loadingMove = true;
        const f = this.quaryFilter;
        const query = {
          lesson_master: this.formMove.lesson.id,
          class_master: f.grade,
          classroom: f.class,
          group_from: this.dataEdit.id,
          group_to: this.formMove.moveTo
        };

        moveGroupOfSubject(query)
          .then(res => {
            if (res.data.code) {
              this.$store.commit("CALL_SNACKBAR", {
                msg: i18n.t("gos.success_update"),
                color: "success"
              });

              this.subjectData = this.subjectData.filter(
                r => r.id != query.lesson_master
              );
              this.openMoveDialog = false;
              this.$emit("triggerData");
            } else {
              this.$store.commit("CALL_SNACKBAR", {
                msg: res.data.message,
                color: "error"
              });
            }
            this.loadingMove = false;
          })
          .catch(() => (this.loadingMove = false));
      }
    },
    save() {
      if (this.$refs.GosForm.validate()) {
        const data = this.form;
        const f = JSON.parse(JSON.stringify(this.quaryFilter));
        this.loadingSave = true;

        const newForm = {
          school_year: f.school_year,
          label: data.label,
          class_master: f.grade,
          classroom: f.class,
          lessons: data.subjects.map(r => r.id),
          add_to_grade: data.add_to_grade
        };

        if (this.dataEdit.id) {
          updateGroupOfSubject({
            ...newForm,
            report_lesson_group: this.dataEdit.id
          })
            .then(res => {
              if (res.data.code) {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: i18n.t("gos.success_update"),
                  color: "success"
                });
                this.closeHandler("save");
                this.subjectData = [];
              } else {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: res.data.message,
                  color: "error"
                });
              }
              this.loadingSave = false;
            })
            .catch(() => {
              this.loadingSave = false;
            });
        } else {
          createGoS(newForm)
            .then(res => {
              if (res.data.code) {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: i18n.t("gos.success_create"),
                  color: "success"
                });
                this.closeHandler("save");
                this.subjectData = [];
              } else {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: res.data.message,
                  color: "error"
                });
              }
              this.loadingSave = false;
            })
            .catch(() => {
              this.loadingSave = false;
            });
        }
      }
    }
  }
};
</script>
